import { ENVIRONMENT } from '@experiences/constants';
import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import { UiErrorBoundary } from '@experiences/ui-common';
import { getEnvironment } from '@experiences/util';
import { ApToastManagerInternal } from '@uipath/portal-shell-react';
import React, {
    StrictMode,
    useEffect,
} from 'react';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

import { Router } from './Router';
import store from './store';

export const App = () => {
    useEffect(() => {
        // If search params includes the spa_module key, create a cookie `microfrontends="true"`
        // that expires in 400 days and reload the page to serve the new index
        const spaModule = new URLSearchParams(window.location.search).get('spa-module');
        const isLocalOrAlp = [ ENVIRONMENT.LOCAL, ENVIRONMENT.ALP ].includes(getEnvironment());

        if (spaModule && isLocalOrAlp && !document.cookie.includes('microfrontends')) {
            document.cookie = `microfrontends=true; max-age=34560000; path=/;`;
            window.location.reload();
        }
    }, []);

    return (
        <StrictMode>
            <UiErrorBoundary>
                <Provider store={store}>
                    <IntlProvider>
                        <ApolloThemeProvider>
                            <SWRConfig value={{ revalidateOnFocus: false }}>
                                <Router />
                                <ApToastManagerInternal />
                            </SWRConfig>
                        </ApolloThemeProvider>
                    </IntlProvider>
                </Provider>
            </UiErrorBoundary>
        </StrictMode>
    );
};
